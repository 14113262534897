import ax from '../../helpers/restful/service';

export const state = {}

export const mutations = {}

export const getters = {}

export const actions = {
    // eslint-disable-next-line no-unused-vars
    async checkPrivilege({ commit }, { identity_number } = {}) {
        const resp = await ax.post("/check-privilege", { identity_number: identity_number}, {}).catch((e) => {
            console.error("error:", e);
            if(e.status >= 400 && e.status <= 409) return e;
            return false;
        });
        if(resp == null || resp == undefined || !resp) return false;
        
        if(resp.status == 409) return resp;
        
        if(resp.data == null || resp.data == undefined) return false;
        
        if(resp.status != 200) return false;
        
        return resp.data;
        
    },
    
    // eslint-disable-next-line no-unused-vars
    async getLatestUserInfo({ commit }, { uid } = {}) {
        const resp = await ax.post("/get-latest-user-info", { uid: uid }, {}).catch((e) => {
            console.error("error:", e);
            if(e.status >= 400 && e.status <= 409) return e;
            return false;
        });
        if(resp == null || resp == undefined || !resp) return false;
        
        if(resp.status == 409) return resp;

        if(resp.data == null || resp.data == undefined) return false;
        
        if(resp.status != 200) return false;
        
        window.localStorage.setItem("auth.currentUser", JSON.stringify(resp.data));

        return resp.data;

    },

    // eslint-disable-next-line no-unused-vars
    async verifyPhone({ commit }, { phone } = {}) {
        const resp = await ax.post("/verify-phone", { phone: phone}, {}).catch((e) => {
            console.error("error:", e);
            if(e.status >= 400 && e.status <= 409) return e;
            return false;
        });
        if(resp == null || resp == undefined || !resp) return false;
        
        if(resp.status == 409) return resp;

        if(resp.data == null || resp.data == undefined) return false;
        
        if(resp.status != 200) return false;

        return resp.data;
    },

    // eslint-disable-next-line no-unused-vars
    async registedTeacher({ commit }, data = {}) {
        console.log("data", data);
        const resp = await ax.post("/registed-teacher", data, {}).catch((e) => {
            console.error("error:", e);
            if(e.status >= 400 && e.status <= 409) return e;
            return false;
        });
        if(resp == null || resp == undefined || !resp) return false;
        
        if(resp.status == 409) return resp;

        if(resp.data == null || resp.data == undefined) return false;
        
        if(resp.status != 200) return false;
        
        return resp.data;
    },
}
