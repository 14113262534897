import { mapState, mapGetters, mapActions } from 'vuex';

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const authRestfulComputed = {
  ...mapState('authRestful', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('authRestful', ['loggedIn', 'getCurrentUser']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader
  })
}

export const teacherMainLayoutComputed = {
  ...mapState('teacherMainLayout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader,
    user: (state) => state.user,
  })
}

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword']);

export const helperFuncMethods = mapActions('helperFunc', ['inputOnlyNumber']);

export const apiRestfulMethods = mapActions('apiRestful', ['checkPrivilege']);

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue']);

//teacher main layout
export const teacherMainLayoutMethods = mapActions('teacherMainLayout', [ ]);

export const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout']);

export const authRestfulMethods = mapActions('authRestful', ['logIn', 'logOut']);

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear']);
