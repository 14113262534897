export default [
  {
    path: '/',
    name: 'origin',
    redirect: { name: 'login' },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/teacher-registed/login'),
  },
  // {
  //   path: '/main',
  //   name: 'main',
  //   component: () => import('../views/pages/teacher-registed/main'),
  // },
  {
    path: '/main',
    name: 'main',
    redirect: { path: '/course-registed' },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/pages/teacher-registed/profile'),
  },
  {
    path: '/wizard',
    redirect: { path: '/course-registed' },
  },
  {
    path: '/teacher-registed/wizard',
    name: 'teacher-registed-wizard',
    component: () => import('../views/pages/teacher-registed/wizard'),
  },
  {
    path: '/teacher-registed',
    name: 'teacher-registed',
    // redirect: { path: '/course-registed' },
    component: () => import('../views/pages/teacher-registed/wizard'),
  },
  {
    path: '/view-info/:attach',
    name: 'view-info',
    component: () => import('../views/pages/teacher-registed/view-info'),
  },

  // Learning
  {
    path: '/teacher-registed/course-registed',
    redirect: { path: '/course-registed' },
  },
  {
    path: '/course-registed',
    name: 'course-registed',
    component: () => import('../views/pages/teacher-registed/course-registed'),
  },
  //admin
  {
    path: '/admin-summary-registed-list',
    name: 'admin-summary-registed-list',
    component: () => import('../views/pages/teacher-registed/registed-list'),
  },
  {
    path: '/teacher-registed/course-video',
    redirect: { path: '/course-video' },
  },
  {
    path: '/course-video',
    name: 'course-video',
    component: () => import('../views/pages/teacher-registed/course-video'),
  },
  {
    path: '/teacher-registed/course-test',
    redirect: { path: '/course-test' },
  },
  {
    path: '/course-test',
    name: 'coures-test',
    component: () => import('../views/pages/teacher-registed/course-test'),
  },
  {
    path: '/my-courses',
    name: 'my-courses',
    component: () => import('../views/pages/teacher-registed/my-courses'),
  },
  {
    path: '/course-learning',
    name: 'course-learning',
    component: () => import('../views/pages/teacher-registed/course-learning'),
  },
  {
    path: '/course-exam',
    name: 'coures-exam',
    component: () => import('../views/pages/teacher-registed/course-exam'),
  },
  {
    path: '/course-inspection',
    name: 'coures-inspection',
    component: () => import('../views/pages/teacher-registed/course-inspection'),
  },
  {
    path: '/course-inspection-approve',
    name: 'coures-inspection-approve',
    component: () => import('../views/pages/teacher-registed/course-inspection-approve'),
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/pages/utility/404'),
  },
]