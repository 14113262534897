export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
    inputOnlyNumber(evt, size = 10, p) {
        evt = (evt) ? evt : window.event;
        const charCode = (evt.which) ? evt.which : evt.keyCode;

        if (size <= p.length) return evt.preventDefault();

        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            return evt.preventDefault();
        }
        return true;
    },
}


